import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { UsersService } from "../../../account-management/users/users.service";
import { IncidentsConfig } from "../../../../models/shared";

import { IncidentConfigService } from "./incident-config.service";

@Component({
    selector: "app-incident-config",
    templateUrl: "./incident-config.component.html"
})
export class IncidentConfigComponent implements OnInit, OnDestroy {
    loading = true;
    saving = false;
    saved = false;
    incidentsConfig: IncidentsConfig;

    sortedByText = "NONE";
    sortedByColumn: string;
    isAdmin: boolean;

    autoPageSize: boolean;
    selectedPageSize: string | number;

    private incidentsConfigSubscription: Subscription;

    constructor(private ics: IncidentConfigService, private userService: UsersService) {}

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.incidentsConfigSubscription = this.ics.incidentsConfig.subscribe(incidentsConfig => {
            this.incidentsConfig = incidentsConfig;
        });

        this.loading = false;
    }

    ngOnDestroy() {
        this.incidentsConfigSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        await this.ics.upsertIncidentsConfig(this.incidentsConfig);

        this.saved = true;
        this.saving = false;
    }
}
