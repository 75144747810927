import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";
import { take } from "rxjs/operators";

import { FlappingService } from "./flapping-detection/flapping.service";
import { IncidentConfigService } from "./incident-config/incident-config.service";
import { UsersService } from "../../account-management/users/users.service";
import { KafkaConfigsService } from "./kafka-config/kafka-config.service";

@Injectable({
    providedIn: "root"
})
export class NotificationsResolverService implements Resolve<boolean> {
    private flapping = false;
    private kafka = false;
    private incidentConfig = false;

    constructor(
        private fs: FlappingService,
        private ics: IncidentConfigService,
        private ks: KafkaConfigsService,
        private userService: UsersService
    ) {}

    resolve(): Observable<boolean> {
        return new Observable((observe: Subscriber<boolean>) => {
            this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
                if (perm.is_admin || perm.is_zixi_admin || perm.is_zixi_support_write) {
                    this.fs.refreshFlappingConfig().subscribe(() => {
                        this.flapping = true;
                        if (this.flapping && this.kafka && this.incidentConfig) {
                            observe.next(true);
                            observe.complete();
                        }
                    });
                    this.ics.refreshIncidentsConfig().subscribe(() => {
                        this.incidentConfig = true;
                        if (this.flapping && this.kafka && this.incidentConfig) {
                            observe.next(true);
                            observe.complete();
                        }
                    });
                    this.ks.refreshKafkaConfigs().subscribe(() => {
                        this.kafka = true;
                        if (this.flapping && this.kafka && this.incidentConfig) {
                            observe.next(true);
                            observe.complete();
                        }
                    });
                } else {
                    this.incidentConfig = this.kafka = this.flapping = true;
                }
            });
        });
    }
}
