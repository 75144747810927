import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../../constants/constants";
import { APIResponse, FlappingConfig } from "../../../../models/shared";

import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class FlappingService {
    flappingConfig: Observable<FlappingConfig>;
    private flappingConfig$: ReplaySubject<FlappingConfig>;
    private dataStore: {
        flapping: FlappingConfig;
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            flapping: undefined
        };

        this.flappingConfig$ = new ReplaySubject(1) as ReplaySubject<FlappingConfig>;
        this.flappingConfig = this.flappingConfig$.asObservable();
    }

    private prepFlappingConfig(item: FlappingConfig) {
        return item;
    }

    private updateStore(flappingConfig: FlappingConfig, merge: boolean): void {
        this.prepFlappingConfig(flappingConfig);

        if (merge) {
            const current = this.dataStore.flapping;

            this.dataStore.flapping = Object.assign(current || {}, flappingConfig);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.flapping = flappingConfig;
        }
    }

    refreshFlappingConfig(): Observable<FlappingConfig> {
        const flapping$ = this.http
            .get<APIResponse<FlappingConfig>>(Constants.apiUrl + Constants.apiUrls.configuration.flapping)
            .pipe(share());

        flapping$.subscribe(
            data => {
                const flapping: FlappingConfig = data.result;

                this.updateStore(flapping, true);

                this.flappingConfig$.next(Object.assign({}, this.dataStore).flapping);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return flapping$.pipe(map(r => r.result));
    }

    async upsertFlappingConfig(model: FlappingConfig): Promise<boolean> {
        try {
            const result = await this.http
                .post<{ success: boolean }>(Constants.apiUrl + Constants.apiUrls.configuration.flapping, model)
                .toPromise();

            if (!result.success) return false;

            this.updateStore(model, false);
            this.flappingConfig$.next(Object.assign({}, this.dataStore).flapping);

            return true;
        } catch (error) {
            return false;
        }
    }
}
