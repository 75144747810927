import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../../constants/constants";
import { APIResponse, IncidentsConfig } from "../../../../models/shared";

import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class IncidentConfigService {
    incidentsConfig: Observable<IncidentsConfig>;
    private incidentsConfig$: ReplaySubject<IncidentsConfig>;
    private dataStore: {
        incidentsConfig: IncidentsConfig;
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            incidentsConfig: undefined
        };

        this.incidentsConfig$ = new ReplaySubject(1) as ReplaySubject<IncidentsConfig>;
        this.incidentsConfig = this.incidentsConfig$.asObservable();
    }

    private prepIncidentConfig(item: IncidentsConfig) {
        return item;
    }

    private updateStore(incidentConfig: IncidentsConfig, merge: boolean): void {
        this.prepIncidentConfig(incidentConfig);

        if (merge) {
            const current = this.dataStore.incidentsConfig;

            this.dataStore.incidentsConfig = Object.assign(current || {}, incidentConfig);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.incidentsConfig = incidentConfig;
        }
    }

    refreshIncidentsConfig(): Observable<IncidentsConfig> {
        const incidentsConfig$ = this.http
            .get<APIResponse<IncidentsConfig>>(Constants.apiUrl + Constants.apiUrls.configuration.incidents)
            .pipe(share());

        incidentsConfig$.subscribe(
            data => {
                const incidentConfig: IncidentsConfig = data.result;

                this.updateStore(incidentConfig, true);

                this.incidentsConfig$.next(Object.assign({}, this.dataStore).incidentsConfig);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return incidentsConfig$.pipe(map(r => r.result));
    }

    async upsertIncidentsConfig(model: IncidentsConfig): Promise<boolean> {
        try {
            const result = await this.http
                .post<{ success: boolean }>(Constants.apiUrl + Constants.apiUrls.configuration.incidents, model)
                .toPromise();

            if (!result.success) return false;

            this.updateStore(model, false);
            this.incidentsConfig$.next(Object.assign({}, this.dataStore).incidentsConfig);

            return true;
        } catch (error) {
            return false;
        }
    }
}
