import { Component, OnInit } from "@angular/core";
import { UsersService } from "../../account-management/users/users.service";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { UserPermissions } from "src/app/models/shared";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html"
})
export class NotificationsComponent implements OnInit {
    userPermissions: UserPermissions;

    constructor(
        private userService: UsersService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("NOTIFICATIONS"));
    }

    ngOnInit() {
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });
    }
}
