import { Component, inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { PagerDutyService } from "./pager-duty.service";
import { PagerDuty } from "src/app/models/shared";
import { filter, map, tap } from "rxjs";

@Component({
    selector: "app-pager-duty",
    templateUrl: "./pager-duty.component.html"
})
export class PagerDutyComponent {
    private formBuilder = inject(FormBuilder);
    private pagerDutyService = inject(PagerDutyService);
    private pagerDutyId?: number;
    loading = true;
    form = this.formBuilder.group({
        name: ["", Validators.required],
        api_key: ["", Validators.required],
        send_incidents: [false]
    });
    pagerDuties$ = this.pagerDutyService.pagerDuties$.pipe(
        tap(pagerDuties => {
            if (Array.isArray(pagerDuties) && pagerDuties.length) {
                this.pagerDutyId = pagerDuties[0].id;
                this.form.patchValue(pagerDuties[0]);
            }
            this.loading = false;
        })
    );
    isFormSubmitted = false;
    saving = false;
    saved = false;

    async ngSubmit() {
        this.isFormSubmitted = true;
        if (this.form.invalid) return;
        this.saving = true;
        const pagerDuty = this.pagerDutyId
            ? await this.pagerDutyService.updatePagerDuty(this.form.value, this.pagerDutyId)
            : await this.pagerDutyService.createPagerDuty(this.form.value);
        this.saving = false;
        this.saved = !!pagerDuty;
    }
}
