<div class="container-fluid">
    <div class="row">
        <div class="col mt-3 mb-3">
            <p [innerHTML]="'FLAPPING_1' | translate"></p>
            <p [innerHTML]="'FLAPPING_2' | translate"></p>
            <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" class="">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="window" [ngClass]="{ 'is-invalid': form.submitted && window.errors }">
                                {{ "WINDOW" | translate }} [{{ "MINUTES_L" | translate }}]
                            </label>
                            <input
                                type="number"
                                id="window"
                                name="window"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && window.errors }"
                                placeholder="{{ 'WINDOW' | translate }}"
                                [(ngModel)]="flappingConfig.window"
                                #window="ngModel"
                                [min]="5"
                                [max]="30"
                                required
                            />
                            <div *ngIf="window.invalid" class="invalid-feedback">
                                <div *ngIf="window.errors.required">{{ "WINDOW" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                                <div *ngIf="window.errors.min || window.errors.max">
                                    {{ "WINDOW" | translate }} {{ "MUST_BE_BETWEEN_5_AND_30" | translate }}.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="events" [ngClass]="{ 'is-invalid': form.submitted && events.errors }">
                                {{ "STATE_CHANGES" | translate }}
                            </label>
                            <input
                                type="number"
                                id="events"
                                name="events"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && events.errors }"
                                placeholder="{{ 'STATE_CHANGES' | translate }}"
                                [(ngModel)]="flappingConfig.events"
                                #events="ngModel"
                                [min]="1"
                                [max]="30"
                                required
                            />
                            <div *ngIf="events.invalid" class="invalid-feedback">
                                <div *ngIf="events.errors.required">{{ "STATE_CHANGES" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                                <div *ngIf="events.errors.min || events.errors.max">
                                    {{ "STATE_CHANGES" | translate }} {{ "MUST_BE_BETWEEN_1_AND_30" | translate }}.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="cooldown" [ngClass]="{ 'is-invalid': form.submitted && cooldown.errors }">
                                {{ "COOLDOWN" | translate }} [{{ "MINUTES_L" | translate }}]
                            </label>
                            <input
                                type="number"
                                id="cooldown"
                                name="cooldown"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && cooldown.errors }"
                                placeholder="{{ 'COOLDOWN' | translate }}"
                                [(ngModel)]="flappingConfig.cooldown"
                                #cooldown="ngModel"
                                [min]="0"
                                [max]="60"
                                required
                            />
                            <div *ngIf="cooldown.invalid" class="invalid-feedback">
                                <div *ngIf="cooldown.errors.required">{{ "COOLDOWN" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                                <div *ngIf="cooldown.errors.min || cooldown.errors.max">
                                    {{ "COOLDOWN" | translate }} {{ "MUST_BE_BETWEEN_0_AND_60" | translate }}.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button zmid="form-submit" type="submit" class="btn btn-primary me-2" [disabled]="saving">
                            {{ "UPDATE" | translate }}
                        </button>
                        <span *ngIf="saving">{{ "SAVING" | translate }}...</span>
                        <span *ngIf="saved">{{ "SAVED" | translate }}!</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
