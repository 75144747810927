import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { UsersService } from "../../../account-management/users/users.service";
import { FlappingConfig } from "../../../../models/shared";

import { FlappingService } from "./flapping.service";

@Component({
    selector: "app-flapping-config",
    templateUrl: "./flapping.component.html"
})
export class FlappingConfigComponent implements OnInit, OnDestroy {
    loading = true;
    saving = false;
    saved = false;
    flappingConfig: FlappingConfig;

    sortedByText = "NONE";
    sortedByColumn: string;
    isAdmin: boolean;

    autoPageSize: boolean;
    selectedPageSize: string | number;

    private flappingsSubscription: Subscription;

    constructor(private fs: FlappingService, private userService: UsersService) {}

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.flappingsSubscription = this.fs.flappingConfig.subscribe(flappingConfig => {
            this.flappingConfig = flappingConfig;
        });

        this.loading = false;
    }

    ngOnDestroy() {
        this.flappingsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        await this.fs.upsertFlappingConfig(this.flappingConfig);

        this.saved = true;
        this.saving = false;
    }
}
