<div class="container-fluid" *ngIf="{ pagerDuties: pagerDuties$ | async } && !loading">
    <div class="row">
        <div class="col mt-3 mb-3">
            <form [formGroup]="form" (ngSubmit)="ngSubmit()">
                <!-- Name -->
                <div class="col-6">
                    <app-input-generic-field
                        [textFieldName]="'NAME' | translate"
                        [formControl]="form.controls.name"
                        type="text"
                        [isParentFormSubmitted]="isFormSubmitted"
                        [disabled]="saving"
                    ></app-input-generic-field>

                    <!-- API_KEY -->
                    <app-input-generic-field
                        [textFieldName]="'API_KEY' | translate"
                        [formControl]="form.controls.api_key"
                        type="text"
                        [isParentFormSubmitted]="isFormSubmitted"
                        [disabled]="saving"
                    ></app-input-generic-field>

                    <!-- SEND_INCIDENTS -->
                    <app-checkbox-field
                        [label]="'SEND_INCIDENTS' | translate"
                        [formControl]="form.controls.send_incidents"
                        [id]="'send_incidents'"
                    ></app-checkbox-field>
                </div>
                <div class="row">
                    <div class="col">
                        <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button zmid="form-submit" type="submit" class="btn btn-primary me-2" [disabled]="saving">
                            {{ "UPDATE" | translate }}
                        </button>
                        <span *ngIf="saving">{{ "SAVING" | translate }}...</span>
                        <span *ngIf="saved">{{ "SAVED" | translate }}!</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
