import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";

import { KafkaConfig } from "../../../../../models/shared";
import { KafkaConfigsService } from "../kafka-config.service";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-kafka-form",
    templateUrl: "./kafka-form.component.html"
})
export class KafkaFormComponent {
    kafka = new KafkaConfig();
    kafkaId: number;
    authMechanisms = ["plain", "scram-sha-256", "scram-sha-512"];

    submitted = false;
    isEdit = false;
    loading = true;
    saving = false;
    constants = Constants;

    constructor(private route: ActivatedRoute, private router: Router, private ks: KafkaConfigsService) {
        this.route.paramMap.subscribe(params => {
            this.kafkaId = parseInt(params.get("id"), 10);
            if (this.kafkaId) {
                this.isEdit = true;

                this.ks.kafkaConfigs.pipe(take(1)).subscribe(kafkas => {
                    this.kafka = Object.assign(
                        {},
                        kafkas.find(k => k.id === this.kafkaId)
                    );
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        });
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.kafka.name,
            servers: this.kafka.servers,
            topic: this.kafka.topic,
            username: this.kafka.username,
            password: this.kafka.password ? this.kafka.password : undefined,
            auth_mechanism: this.kafka.auth_mechanism,
            send_events: !!this.kafka.send_events,
            send_scte: !!this.kafka.send_scte
        };

        if (this.isEdit) {
            const result = await this.ks.updateKafkaConfig(this.kafka, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.notifications]);
            } else this.saving = false;
        } else {
            const result = await this.ks.createKafkaConfig(model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.notifications]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.notifications]);
    }
}
