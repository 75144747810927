<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_KAFKA_CONFIG" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_KAFKA_CONFIG" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <!-- Name -->
                    <div class="form-group">
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="kafka.name"
                            required
                            minlength="{{ minLength }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <!-- Servers -->
                    <div class="form-group">
                        <label for="servers" [ngClass]="{ 'is-invalid': form.submitted && servers.errors }"
                            >{{ "SERVERS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="servers"
                            name="servers"
                            placeholder="{{ 'SERVERS' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && servers.errors }"
                            [(ngModel)]="kafka.servers"
                            required
                            minlength="{{ minLength }}"
                            #servers="ngModel"
                        />
                        <small translate>KAFKA_HOST_HELP</small>
                        <div *ngIf="servers.invalid" class="invalid-feedback">
                            <div *ngIf="servers.errors.required">{{ "SERVERS" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="servers.errors.minlength">
                                {{ "SERVERS" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Topic -->
                    <div class="form-group">
                        <label for="topic" [ngClass]="{ 'is-invalid': form.submitted && topic.errors }"
                            >{{ "TOPIC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="topic"
                            name="topic"
                            placeholder="{{ 'TOPIC' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && topic.errors }"
                            [(ngModel)]="kafka.topic"
                            required
                            minlength="{{ minLength }}"
                            #topic="ngModel"
                        />
                        <div *ngIf="topic.invalid" class="invalid-feedback">
                            <div *ngIf="topic.errors.required">{{ "TOPIC" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="topic.errors.minlength">
                                {{ "TOPIC" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Username -->
                    <div class="form-group">
                        <label for="username" [ngClass]="{ 'is-invalid': form.submitted && username.errors }"
                            >{{ "USERNAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="{{ 'USERNAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && username.errors }"
                            [(ngModel)]="kafka.username"
                            required
                            minlength="{{ minLength }}"
                            #username="ngModel"
                        />
                        <div *ngIf="username.invalid" class="invalid-feedback">
                            <div *ngIf="username.errors.required">{{ "USERNAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="username.errors.minlength">
                                {{ "USERNAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Password -->
                    <div class="form-group">
                        <label for="password" [ngClass]="{ 'is-invalid': form.submitted && password.errors }"
                            >{{ "PASSWORD" | translate }}<fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="password"
                            name="password"
                            placeholder="{{ 'PASSWORD' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && password.errors }"
                            [(ngModel)]="kafka.password"
                            [required]="!isEdit"
                            minlength="{{ minLength }}"
                            #password="ngModel"
                        />
                        <small *ngIf="isEdit" translate>BLANK_SECRET_EDIT</small>
                        <div *ngIf="password.invalid" class="invalid-feedback">
                            <div *ngIf="password.errors.required">{{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="password.errors.minlength">
                                {{ "PASSWORD" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Mechanism -->
                    <div class="form-group">
                        <div class="form-group">
                            <label for="auth_mechanism">{{ "AUTHENTICATION" | translate }}</label>
                            <ng-select
                                class="form-control"
                                id="auth_mechanism"
                                name="auth_mechanism"
                                [items]="authMechanisms"
                                [clearSearchOnAdd]="true"
                                required
                                bindValue="auth_mechanism"
                                placeholder="{{ 'AUTHENTICATION' | translate }}"
                                [(ngModel)]="kafka.auth_mechanism"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <!-- Send Events -->
                    <div class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="events" name="events" [(ngModel)]="kafka.send_events" />
                            <label class="form-check-label" for="events">{{ "EVENTS" | translate }} </label>
                        </div>
                    </div>
                    <!-- Send SCTE -->
                    <div class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="scte" name="scte" [(ngModel)]="kafka.send_scte" />
                            <label class="form-check-label" for="scte">{{ "SCTE_35" | translate }} </label>
                        </div>
                    </div>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
