import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { share, map, catchError, of, firstValueFrom } from "rxjs";
import { Constants } from "src/app/constants/constants";
import { APIResponse, PagerDuty } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class PagerDutyService {
    httpClient = inject(HttpClient);
    pagerDuties$ = this.httpClient.get<APIResponse<PagerDuty[]>>(Constants.apiUrl + Constants.apiUrls.pagerDuty).pipe(
        share(),
        catchError(err => of<false>(false)),
        map(response => (response && response.success ? response.result : false))
    );

    async createPagerDuty(pagerDuty: Partial<PagerDuty>) {
        try {
            const result = await firstValueFrom(
                this.httpClient.post<APIResponse<PagerDuty>>(Constants.apiUrl + Constants.apiUrls.pagerDuty, pagerDuty)
            );
            return result.success ? result.result : false;
        } catch (err) {
            return false;
        }
    }
    async updatePagerDuty(pagerDuty: Partial<PagerDuty>, id: number) {
        try {
            const result = await firstValueFrom(
                this.httpClient.put<APIResponse<PagerDuty>>(
                    Constants.apiUrl + Constants.apiUrls.pagerDuty + `/${id}`,
                    pagerDuty
                )
            );
            return result.success ? result.result : false;
        } catch (err) {
            return false;
        }
    }
}
