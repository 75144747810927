import {
    Component,
    OnInit,
    OnDestroy,
    QueryList,
    ViewChildren,
    ViewChild,
    ElementRef,
    HostListener
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { NgbSortableHeader, SortEvent, SortDirection } from "../../../../directives/sortable.directive";

import { ModalService } from "../../../../components/shared/modals/modal.service";
import { SharedService } from "../../../../services/shared.service";
import { UsersService } from "../../../account-management/users/users.service";
import { KafkaConfig } from "../../../../models/shared";

import { KafkaConfigsService } from "./kafka-config.service";
import { Constants } from "src/app/constants/constants";
import { Router } from "@angular/router";

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: string;
    sortDirection: SortDirection;
    startNum: number;
    endNum: number;
}

@Component({
    selector: "app-kafka-config-list",
    templateUrl: "./kafka-config.component.html"
})
export class KafkaConfigsListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    kafkaConfigs: KafkaConfig[] = [];
    filteredKafkaConfigs: KafkaConfig[];
    urls = Constants.urls;

    sortedByText = "NONE";
    sortedByColumn: string;
    isAdmin: boolean;

    autoPageSize: boolean;
    selectedPageSize: string | number;

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    @ViewChild("listPanel", { static: true }) listPanel: ElementRef;
    @ViewChild("listTop", { static: true }) listTop: ElementRef;
    @ViewChild("listBottom", { static: true }) listBottom: ElementRef;

    private kafkaConfigsSubscription: Subscription;

    private kafkaConfigsList$ = new BehaviorSubject<KafkaConfig[]>([]);
    private totalConfigs$ = new BehaviorSubject<number>(0);

    private state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: "",
        sortColumn: "name",
        sortDirection: "asc",
        startNum: 0,
        endNum: 0
    };

    searchTermArray: string[];

    @HostListener("window:resize", [])
    private onResize() {
        if (this.autoPageSize) {
            this.calcRows();
            this.prepTableData();
        }
    }

    constructor(
        private router: Router,
        private ks: KafkaConfigsService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService
    ) {}

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        // local storage
        this.searchTerm = localStorage.getItem("kafka.searchTerm") ? localStorage.getItem("kafka.searchTerm") : "";
        this.sortColumn = localStorage.getItem("kafka.sortColumn") ? localStorage.getItem("kafka.sortColumn") : "name";
        this.sortDirection = localStorage.getItem("kafka.sortDirection")
            ? localStorage.getItem("kafka.sortDirection")
            : "asc";

        // Get pageSize
        if (!localStorage.getItem("pageSize") || localStorage.getItem("pageSize") === "auto") {
            this.selectedPageSize = "auto";
            this.autoPageSize = true;
            this.calcRows();
        } else {
            this.pageSize = +localStorage.getItem("pageSize");
            this.selectedPageSize = +localStorage.getItem("pageSize");
            this.autoPageSize = false;
        }

        this.kafkaConfigsSubscription = this.ks.kafkaConfigs.subscribe(kafkaConfigs => {
            this.kafkaConfigs = kafkaConfigs;
            if (this.kafkaConfigs) {
                this.prepTableData();
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        this.kafkaConfigsSubscription.unsubscribe();
    }

    resetSearch() {
        this.searchTerm = "";
    }

    pageSizeChanged(pageSize) {
        if (pageSize === "auto") {
            localStorage.setItem("pageSize", "auto");
            this.calcRows();
            this.prepTableData();
            this.autoPageSize = true;
        } else {
            localStorage.setItem("pageSize", pageSize.toString());
            this.pageSize = pageSize;
            this.prepTableData();
            this.autoPageSize = false;
        }
    }

    calcRows() {
        this.pageSize = this.sharedService.calcRowsTab(
            this.listPanel.nativeElement.offsetHeight,
            51,
            this.listBottom.nativeElement.offsetHeight
        );
    }

    async refresh() {
        this.refreshing = true;

        const kafkaConfigs = this.ks.refreshKafkaConfigs().toPromise();
        await Promise.all([kafkaConfigs]);

        this.refreshing = false;
    }

    async test(kafkaConfig: KafkaConfig) {
        // TODO: open modal showing test progress and result?
        const testResult = await this.ks.testKafkaConfig(kafkaConfig);
        alert(testResult.message);
    }

    async edit(kafkaConfig: KafkaConfig) {
        this.router.navigate([Constants.urls.configuration.notifications, "kafka", kafkaConfig.id, "edit"]);
    }

    async delete(kafkaConfig: KafkaConfig) {
        await this.modalService.confirm(
            "DELETE",
            "KAFKA_CONFIG",
            async () => {
                await this.ks.deleteKafkaConfig(kafkaConfig);
                this.ks.refreshKafkaConfigs();
            },
            kafkaConfig.name
        );
    }

    onSort({ column, direction }: SortEvent, name: string) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.sortedByColumn = column;
        this.sortedByText = name;
        this.sortColumn = column;
        this.sortDirection = direction;
    }

    get kafkaConfigs$() {
        return this.kafkaConfigsList$.asObservable();
    }
    get total$() {
        return this.totalConfigs$.asObservable();
    }
    get page() {
        return this.state.page;
    }
    set page(page: number) {
        this._set({ page });
    }
    get pageSize() {
        return this.state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    get startNum() {
        return this.state.startNum;
    }
    set startNum(startNum: number) {
        this._set({ startNum });
    }
    get endNum() {
        return this.state.endNum;
    }
    set endNum(endNum: number) {
        this._set({ endNum });
    }
    get searchTerm() {
        return this.state.searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
        localStorage.setItem("kafka.searchTerm", searchTerm);
        this.searchTermArray = this.sharedService.createArrayFromBooleanSearch(searchTerm);
    }
    get sortColumn() {
        return this.state.sortColumn;
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
        localStorage.setItem("kafka.sortColumn", sortColumn);
    }
    get sortDirection() {
        return this.state.sortDirection;
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
        localStorage.setItem("kafka.sortDirection", sortDirection);
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepTableData();
    }

    private prepTableData() {
        if (this.kafkaConfigs) {
            const { sortColumn, sortDirection, pageSize, page, searchTerm } = this.state;

            // 1. sort
            let kafkaConfigs = this.sharedService.sort(this.kafkaConfigs, sortColumn, sortDirection);

            // 2. filter
            kafkaConfigs = kafkaConfigs.filter(kc => this.sharedService.matches(kc, searchTerm, this.kcFilter));
            this.filteredKafkaConfigs = kafkaConfigs;
            const total = kafkaConfigs.length;

            // 3. paginate
            kafkaConfigs = kafkaConfigs.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

            // 4. page start & end
            this.state.startNum = page * pageSize - (pageSize - 1);
            this.state.endNum = Math.min(this.state.startNum + pageSize - 1, total);

            if (total === 0) {
                this.state.startNum = 0;
            }

            this.kafkaConfigsList$.next(kafkaConfigs);
            this.totalConfigs$.next(total);
        }
    }

    kcFilter = (kafkaConfig: KafkaConfig, term: string) => {
        return (
            kafkaConfig.name.toLowerCase().includes(term.toLowerCase()) ||
            kafkaConfig.servers.toLowerCase().includes(term.toLowerCase()) ||
            kafkaConfig.topic.toLowerCase().includes(term.toLowerCase())
        );
    };
}
