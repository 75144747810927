<div class="container-fluid">
    <div class="row">
        <div class="col mt-3 mb-3">
            <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" class="">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="window_minutes" [ngClass]="{ 'is-invalid': form.submitted && window_minutes.errors }">
                                {{ "WINDOW" | translate }} [{{ "MINUTES_L" | translate }}]
                            </label>
                            <input
                                type="number"
                                id="window_minutes"
                                name="window_minutes"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && window_minutes.errors }"
                                placeholder="{{ 'WINDOW' | translate }}"
                                [(ngModel)]="incidentsConfig.window_minutes"
                                #window_minutes="ngModel"
                                [min]="5"
                                [max]="30"
                                required
                            />
                            <div *ngIf="window_minutes.invalid" class="invalid-feedback">
                                <div *ngIf="window_minutes.errors.required">{{ "WINDOW" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                                <div *ngIf="window_minutes.errors.min || window_minutes.errors.max">
                                    {{ "WINDOW" | translate }} {{ "MUST_BE_BETWEEN_5_AND_30" | translate }}.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="min_objects" [ngClass]="{ 'is-invalid': form.submitted && min_objects.errors }">
                                {{ "MIN_OBJECTS_COUNT" | translate }}
                            </label>
                            <input
                                type="number"
                                id="min_objects"
                                name="min_objects"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && min_objects.errors }"
                                placeholder="{{ 'MIN_OBJECTS_COUNT' | translate }}"
                                [(ngModel)]="incidentsConfig.min_objects"
                                #min_objects="ngModel"
                                [min]="1"
                                [max]="30"
                                required
                            />
                            <div *ngIf="min_objects.invalid" class="invalid-feedback">
                                <div *ngIf="min_objects.errors.required">{{ "MIN_OBJECTS_COUNT" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                                <div *ngIf="min_objects.errors.min || min_objects.errors.max">
                                    {{ "MIN_OBJECTS_COUNT" | translate }} {{ "MUST_BE_BETWEEN_1_AND_30" | translate }}.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="percentage_affected" [ngClass]="{ 'is-invalid': form.submitted && percentage_affected.errors }">
                                {{ "MIN_OBJECTS_AFFECTED" | translate }}
                            </label>
                            <input
                                type="number"
                                id="percentage_affected"
                                name="percentage_affected"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && percentage_affected.errors }"
                                placeholder="{{ 'MIN_OBJECTS_AFFECTED' | translate }}"
                                [(ngModel)]="incidentsConfig.percentage_affected"
                                #percentage_affected="ngModel"
                                [min]="0"
                                [max]="60"
                                required
                            />
                            <div *ngIf="percentage_affected.invalid" class="invalid-feedback">
                                <div *ngIf="percentage_affected.errors.required">{{ "MIN_OBJECTS_AFFECTED" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                                <div *ngIf="percentage_affected.errors.min || percentage_affected.errors.max">
                                    {{ "MIN_OBJECTS_AFFECTED" | translate }} {{ "MUST_BE_BETWEEN_0_AND_60" | translate }}.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="notify" name="notify" [(ngModel)]="incidentsConfig.notify" />
                                <label class="form-check-label" for="notify">{{ "EMAIL_INCIDENTS" | translate }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button zmid="form-submit" type="submit" class="btn btn-primary me-2" [disabled]="saving">
                            {{ "UPDATE" | translate }}
                        </button>
                        <span *ngIf="saving">{{ "SAVING" | translate }}...</span>
                        <span *ngIf="saved">{{ "SAVED" | translate }}!</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
