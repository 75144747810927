import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Routes
import { NotificationsRouting } from "./notifications.routing";

// Components
import { NotificationsComponent } from "./notifications.component";
import { FlappingConfigComponent } from "./flapping-detection/flapping.component";
import { KafkaConfigsListComponent } from "./kafka-config/kafka-config.component";
import { KafkaFormComponent } from "./kafka-config/kafka-form/kafka-form.component";
import { PagerDutyComponent } from "./pager-duty/pager-duty.component";
import { IncidentConfigComponent } from "./incident-config/incident-config.component";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, NotificationsRouting],
    declarations: [
        NotificationsComponent,
        FlappingConfigComponent,
        IncidentConfigComponent,
        KafkaConfigsListComponent,
        KafkaFormComponent,
        PagerDutyComponent
    ]
})
export class NotificationsModule {}
